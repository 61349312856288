import * as React from "react"
import Slider from "react-slick";

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css";

// styles
import "../styles/css/styles.css"

const Banner = (props) => {
    

    console.log('PROPS CUSTOM -->', props);
    // console.log("🚀 ~ file: Slider.jsx ~ line 10 ~ Banner ~ props", props)

    const settings = props.config ? props.config : {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "10px"
    }

    if(props.sliders == undefined) return null

    return (
        <>
            <Slider {...settings}>
                {
                    props.sliders.map(slide => (
                        <div className="slide">
                            <a href={`${props.path}/${slide.slug}`} title={slide.title.rendered}>
                                <img src={slide._embedded && slide?._embedded["wp:featuredmedia"][0]?.source_url} alt={slide.title.rendered} />
                                <div className="content">
                                    <span className="subtitle" dangerouslySetInnerHTML={{__html: slide.subtitle}} />
                                    <h3 className="title" dangerouslySetInnerHTML={{__html: slide.title.rendered }} />
                                    <button>{ props.buttonLabel }</button>
                                </div>
                            </a>
                        </div>
                    ))
                }
            </Slider>
        </>
    )
}

export default Banner