import * as React from "react"

import defaults from "../dafaults"

// styles
import "../../styles/css/styles.css"

const Search = (props) => {

    const [ type, setType ] = React.useState(null)
    const [ typeSearch, setTypeSearch ] = React.useState(false)
    const [ advanced, setAdvanced ] = React.useState({})

    const [ modelField, setModelField ] = React.useState(false);
    const [ categoryField, setCategoryField ] = React.useState(false);
    const [ assemblerField, setAssemblerField ] = React.useState(false);

    const requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const changeFilter = (e) => {
        
        let name = e.currentTarget.name
        let value = e.currentTarget.value

        fetch(`${defaults.api.base}combo?tipo=1`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setAdvanced({ ...advanced, [name]: { value: value, children: result.Obj.Combo }})
        })
        .catch(error => console.log('error', error));
    }

    const changeCategory = (e) => {

        let name = e.currentTarget.name
        let value = e.currentTarget.value
        
        advanced.tipo.children.find(find => {
            if(find.Codigo === value) {
                setAdvanced({ ...advanced, [name]: { value: value, children: find.Children }})
            }else {
                if(value != 'Selecione')
                    setCategoryField(true);
                else 
                    setCategoryField(false);    
            }
        })
    }

    const changeAssembler = (e) => {

        let name = e.currentTarget.name
        let value = e.currentTarget.value
        
        advanced.CodCategoria.children.find(find => {
            if(find.Codigo === value) {
                setAdvanced({ ...advanced, [name]: { value: value, children: find.Children }})
            }else {
                if(value != 'Selecione')
                    setAssemblerField(true);
                else 
                    setAssemblerField(false);    
            }
        })
    }

    const changeModel = (e) => {
        let name = e.currentTarget.name
        let value = e.currentTarget.value
        
        advanced.CodMontadora.children.find(find => {
            console.log(value)
            if(find.Codigo === value) {
                setAdvanced({ ...advanced, [name]: { value: value, children: find.Children }})
            }else {
                if(value != 'Selecione')
                    setModelField(true);
                else 
                    setModelField(false);    
            }
        })
    }

    const changeDescModel = (e) => {

        let name = e.currentTarget.name
        let value = e.currentTarget.value
        
        advanced.CodModelo.children.find(find => {
            if(find.Codigo === value) {
                setAdvanced({ ...advanced, [name]: { value: value, children: find.Children }})
            }
        })
    }

    const changeMotor = (e) => {

        let name = e.currentTarget.name
        let value = e.currentTarget.value
        
        advanced.CodDescModelo.children.find(find => {
            if(find.Codigo === value) {
                setAdvanced({ ...advanced, [name]: { value: value, children: find.Children }})
            }
        })
    }

    if(advanced === null) return null


    console.log('CHANGE ADVANCED --> ',type);

    return (
        <section className="search">
            <h2>
                {
                    props.internal == true ?
                        <><span>NÃO ENCONTROU?</span> FAÇA UMA NOVA BUSCA</>
                    :
                        <><span>ENCONTRE AQUI</span> O QUE VOCÊ PRECISA</>
                }
            </h2>
                {
                    !typeSearch ?
                        <form action="/busca">
                            <div className="fields">
                                <label className="typeProduct" htmlFor="typeProduct">
                                    Tipo de produto:
                                    <select name="tipo" id="tipo" onChange={(e) => { setType(e.target.value) }}>
                                        <option>Selecione</option>
                                        <option value="1">Filtro</option>
                                        <option value="2">Vela</option>
                                    </select>
                                </label>
                                <label className="chave" htmlFor="chave" >
                                    Palavra-chave:
                                    <input name="chave" id="chave" placeholder="Selecione um tipo de filtro, depois digite aqui sua busca..." disabled={ type == null ? true : false} />
                                </label>
                                {
                                    type != 'Selecione' && type != null ?
                                        <button className="search-advanced-button">ENCONTRAR</button>
                                    :
                                        <button className="btn-disabled-submit" disabled="disabled" onClick={(e) => { e.preventDefault()}}>ENCONTRAR</button>
                                }
                            </div>
                        </form>
                    :
                        <form action="/busca/avancada">
                            <div className="fields">
                                <label className="typeProduct" htmlFor="typeProduct">
                                    Tipo de produto:
                                    <select name="tipo" id="tipo" onChange={(e) => { changeFilter(e)}}>
                                        <option>Selecione</option>
                                        <option value="1">Filtro</option>
                                        <option value="2">Vela</option>
                                    </select>
                                </label>
                                <label className="CodCategoria" htmlFor="CodCategoria">
                                    Categoria:
                                    <select name="CodCategoria" id="CodCategoria" onChange={(e) => { changeCategory(e) }}>
                                        <option>Selecione</option>
                                        {
                                            advanced.tipo != null && advanced.tipo.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <label className="CodMontadora" htmlFor="CodMontadora">
                                    Montadora:
                                    <select name="CodMontadora" id="CodMontadora" onChange={(e) => { changeAssembler(e)}}>
                                        <option>Selecione</option>
                                        {
                                            advanced.CodCategoria != null && advanced.CodCategoria.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <label className="CodModelo" htmlFor="CodModelo">
                                    Modelo:
                                    <select name="CodModelo" id="CodModelo" onChange={(e) => { changeModel(e)}}>
                                        <option>Selecione</option>
                                        {
                                            advanced.CodMontadora != null && advanced.CodMontadora.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <label className="CodDescModelo" htmlFor="CodDescModelo">
                                    Descrição do Modelo:
                                    <select name="CodDescModelo" id="CodDescModelo" onChange={(e) => { changeDescModel(e)}}>
                                        <option>Selecione</option>
                                        {
                                            advanced.CodModelo != null && advanced.CodModelo.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <label className="CodMotor" htmlFor="CodMotor">
                                    Motor:
                                    <select name="CodMotor" id="CodMotor" onChange={(e) => { changeMotor(e)}}>
                                        <option>Selecione</option>
                                        {
                                            advanced.CodDescModelo != null && advanced.CodDescModelo.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                <label className="CodAno" htmlFor="CodAno">
                                    Ano:
                                    <select name="CodAno" id="CodAno">
                                        <option>Selecione</option>
                                        {
                                            advanced.CodMotor != null && advanced.CodMotor.children.map(item => (
                                                <option value={item.Codigo}>{item.Filtro}</option>
                                            ))
                                        }
                                    </select>
                                </label>
                                {
                                    categoryField == true &&
                                    assemblerField == true &&
                                    modelField == true ?
                                        // <button className="search-advanced-button">ENCONTRAR</button>
                                        <button>ENCONTRAR</button>
                                    :
                                        <button className="btn-disabled-submit" disabled="disabled" onClick={(e) => { e.preventDefault()}}>ENCONTRAR</button>
                                }
                            </div>
                        </form>
                }
            <div className="typeSearch">
                <label htmlFor="Busca Rápida">Busca Rápida</label>
                <label className="switch">
                    <input type="checkbox" value={typeSearch} onChange={ () => setTypeSearch(!typeSearch)} />
                    <span className="slider round"></span>
                </label>
                <label htmlFor="Busca Avançada">Busca Avançada</label>
            </div>
        </section>
    )
}

export default Search