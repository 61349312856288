import * as React from "react"
import axios from "axios";

import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

import defaults from "../../../components/dafaults"


// components
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import Sliders from "../../../components/Slider"
import Search from "../../../components/Search/index"

// styles
import "../../../styles/css/styles.css"

const Produto = ({ location }) => {

    const [ typeSearch, setTypeSearch ] = React.useState("Aplicações")
    const [ product, setProduct ] = React.useState(null)
    const [ lancamentos, setLancamentos ] = React.useState(null)
    const [ size, setSize ] = React.useState(null)
    const [ allImages, setAllImages ] = React.useState([])

    React.useEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
    })
    
    const [nav1, setNav1] = React.useState(null);
    const [nav2, setNav2] = React.useState(null);
    const [slider1, setSlider1] = React.useState(null);
    const [slider2, setSlider2] = React.useState(null);
    const [modalImage, setModalImage] = React.useState(false);

    React.useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });

    const sliderRef1 = React.useRef(slider => (setSlider2(slider)))
    const sliderRef2 = React.useRef(slider => (setSlider1(slider)))

    const settingsThumbs = {
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        swipeToSlide: true,
        focusOnSelect: true,
        vertical: true,
        infinite: false
    };

    console.log('PRODUCTS --> ', product);

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${defaults.api.base}produto${location.search}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(product == null)
                setProduct(result.Obj)
        })
        .catch(error => console.log('error', error));

        React.useLayoutEffect(() => {
            const requestOptions = {
              method: 'GET',
              redirect: 'follow'
            };
            
            fetch(`${defaults.api.rest}posts?post_type=lancamento&_embed`, requestOptions)
              .then(response => response.json())
              .then(result => {
                if(lancamentos == null)
                  setLancamentos(result)
              })
              .catch(error => console.log('error', error));
        
          })
        
          const lancamentoConfig = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,      
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          };

          const productLabels = {
            A0: "A0",
            Altura: "Altura",
            CHAVE: "Chave",
            COD_TIPO: "Tipo",
            CodigoComercialWega: "Código Comercial Wega",
            CodigoWega: "Código Wega",
            Comprimento: "Comprimento",
            D: "Dimensão",
            DIAM_TUBO: "Diâmetro do Tubo",
            DescTec: "Descrição Técnica",
            DiametroExt: "Diâmetro Externo",
            DiametroInt: "Diâmetro Interno",
            L1: "L1",
            L2: "L2",
            LT: "L3",
            Largura: "Largura",
            Linha: "Linha",
            Observacao: "Observação",
            T1: "T1",
            TipoFiltro: "Tipo de Filtro",
            V: "V",
            ZAT_ANTRET: "Anti Retorno",
            ZAT_BICOS: "Bicos",
            ZAT_BYPASS: "By Pass",
            ZAT_DTLANC: "Data de Lançamento",
            ZAT_FILTRO: "Filtro",
            ZAT_FORLIN: "Forlin",
            ZAT_LINHA: "Linha",
            ZAT_ROSCA: "Rosca",
            ZAT_SUBST: "Substituto"
          }

          const correctNames = {
            DetailApl: {
                title: "Aplicações",
                columns: [
                    "Linha",
                    "Categoria",
                    "Modelo",
                    "Montadora",
                    "Descrição do Modelo",
                    "Motor",
                    "Ano",
                    "Pos.Montagem"
                ],
                values: [
                    "Linha",
                    "Categoria",
                    "Modelo",
                    "Montadora",
                    "DescModelo",
                    "Motor",
                    "Ano",
                    "ZAU_XCOMR"
                ]
            },
            DetailConv: {
                title: "Conversões",
                columns: [
                    "Código Wega",
                    "Código Concorrente",
                    "Marca"
                ],
                values: [
                    "CodigoComercialWega",
                    "CodigoConcorrente",
                    "Marca"
                ]
            },
            DetailDT: {
                title: ""
            }
        }
    

    const trataParam = (nome) => {
        if(nome == "JFA0999-2" || nome == "JFA-09992" || nome == "JFA09992")
        {
            //console.log('1');
            return "JFA-0999-2";
        }
        else if(nome == "AKX1399/C" || nome == "AKX-1399/C" || nome == "AKX1399C")
        {
            //console.log('2');
            return "AKX-1399/C";
        }
        else if(nome == "CFW0771B")
        {
            return "CFW-0771BB";
        }
        else if(nome == "TFW624B")
        {
            return "TFW-624BB";
        }
        else if(nome == "FCD2058B")
        {
            return "FCD-2058BB";
        }
        else if(nome.indexOf("-") == -1)
        {
            //console.log('3');
            let firstDigit = nome.match(/\d/) // will give you the first digit in the string
            let indexed = nome.indexOf(firstDigit);
            let output = nome.substr(0, indexed) + '-' + nome.substr(indexed);
            return output.replace("/", "-");
        }
        else
        {
            //console.log('4');
            return nome.replace("/", "-");
        }
    }

    const trataNome = (nome) => {
        console.log("🚀 ~ trataNome ~ nome:", nome)
        if(nome == "JFA0999-2" || nome == "JFA-09992" || nome == "JFA09992")
        {
            return "JFA-0999-2";
        }
        else if(nome == "CFW0771B")
        {
            return "CFW-0771BB";
        }
        else if(nome == "TFW624B")
        {
            return "TFW-624BB";
        }
        else if(nome == "FCD2058B")
        {
            return "FCD-2058BB";
        }
        else if(nome.indexOf("-") == -1)
        {
            //console.log(nome + '--');
            let firstDigit = nome.match(/\d/) // will give you the first digit in the string
            let indexed = nome.indexOf(firstDigit);
            let output = nome.substr(0, indexed) + '-' + nome.substr(indexed);
            //console.log(output.replace("/", "-"));
            return output.replace("/", "-");
        }
        else
        {
            return nome.replace("/", "-");;
        }
    }

    const handleImage = () => {
        setModalImage(!modalImage);
    }

    React.useEffect(() => {
        if(product != null) {

            let letter = ["", "B", "C"]
            let urlParams = new URLSearchParams(window.location.search);

            let cod = trataNome(product.DetailDT.CodigoWega); 
            let codCom = product.DetailDT.CodigoComercialWega;

            let images = []

            letter.map((letter) => {
                let img = defaults.api.baseImage + cod.trim() + letter + ".jpg?v=2";
                console.log("🚀 ~ file: index.jsx:271 ~ letter.map ~ img", img)

                if("AKX-3535/1" != codCom || letter != "C") {
                    images.push(img);
                }

            })

            setAllImages(images)

            // letters.map(letter => {
            //     console.log(codWega, letter)
            // })

        }


        // console.log('PRODUCT --> ',product.DetailDT.Linha);


    }, [product])

    console.log("allImages", allImages)

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoHeight: true,
        customPaging: function(i) {
            return (
                <a><img src={allImages[i]} /></a>
            );
        },
    }
       
    
    if(product == null) return null

    return(
        <>
            <Header />
            <div className="searchProduct">
                <div className="container">
                    <div className="images">
                        <Slider 
                            {...settings}
                            ref={sliderRef2}
                        >
                            { 
                                allImages.length > 0 && allImages.map(image => (
                                    <img src={image} alt="" />
                                ))
                            }
                        </Slider>
                    </div>
                    <div className="content">
                        <h1>{ product.DetailDT.CodigoComercialWega }</h1>
                        <div className="cols">
                            <div className="col-1">
                                <strong>Dados Técnicos</strong>
                                <ul>
                                    {
                                        Object.keys(product.DetailDT).map((item, index) => {
                                            if( 
                                                productLabels[item] != 'Linha' &&
                                                productLabels[item] != 'Código Wega' &&
                                                productLabels[item] != 'Filtro' &&
                                                productLabels[item] != 'Forlin' &&
                                                productLabels[item] != 'Tipo' &&
                                                productLabels[item] != 'Data de Lançamento'
                                            ){
                                                return product.DetailDT[item] != "" &&
                                                    <li className="codigo-wega" data-attr={item}>
                                                        <strong className="name">{ productLabels[item] == 'Código Comercial Wega' ? 'Código Wega' : productLabels[item]}</strong>
                                                        <span>{ product.DetailDT[item] }</span>
                                                    </li>
                                            }

                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="searchList">
                <div className="container">
            {
                        product &&
                            Object.keys(product).map(type => {
                                if(correctNames[type]?.title == typeSearch && product[type]?.length > 0) {
                                    return(
                                        <>
                                            <div className="typeSearch">
                                                <label htmlFor="Busca Rápida">Aplicações</label>
                                                <label className="switch">
                                                    <input type="checkbox" value={typeSearch} onChange={ () => setTypeSearch(typeSearch == "Aplicações" ? "Conversões" : "Aplicações")} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <label htmlFor="Busca Avançada">Conversões</label>
                                            </div>
                                            <h2>{ correctNames[type]?.title }</h2>
                                            <div className="list">
                                                {
                                                    size > 991 &&
                                                    <div className="thead">
                                                        {
                                                            correctNames[type]?.columns?.map(column => (
                                                                <div className={column == 'Pos.Montagem' ? 'pos-montagem' : ''}>
                                                                    { column == 'Pos.Montagem' ? <span style={{cursor: 'pointer'}} onClick={handleImage}>{column}</span> : column }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                                {
                                                    product[type].length && product[type]?.map(list => (
                                                        <div className="tbody">
                                                            {
                                                                size < 991 &&
                                                                <ul className="thead">
                                                                {
                                                                    correctNames[type]?.columns?.map(column => (
                                                                        <div>
                                                                            { column }
                                                                        </div>
                                                                    ))
                                                                }
                                                                </ul>
                                                            }
                                                            <ul className="content">
                                                            {
                                                                correctNames[type]?.values?.map(value => (
                                                                    // console.log('VALUES -->', list[value] == '')
                                                                    value == "Modelo" ?
                                                                        <a href={`/busca/veiculo?CodModelo=${list["CodModelo"]}&ano=${list["Ano"]}&motor=${list['Motor']}&linha=${list['Linha']}&categoria=${list['Categoria']}&descmodelo=${list['DescModelo']}`}>
                                                                            { list[value] }
                                                                        </a>
                                                                    :
                                                                        value == "CodigoComercialWega" ?
                                                                            <a href={`/busca/produto?cod=${list[value]}`}>
                                                                                { list[value] }
                                                                            </a>
                                                                        :
                                                                            <div>
                                                                                { list[value] == '' ? '--' : list[value] }
                                                                            </div>
                                                                ))
                                                            }
                                                            </ul>
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    modalImage == true ? 
                                                        <>
                                                            <div className="backdropimage">
                                                            </div>
                                                            <div className="produto-backdrop-image">
                                                                <h2 className="close" onClick={handleImage}>X</h2>
                                                                <img src="https://admin.wegamotors.com/wp-content/uploads/2020/03/catalogo-de-filtros-para-veiculos-pesados-e-utilitarios-2018-2.png" alt="Pesados e Utilitarios" />
                                                            </div>
                                                        </>
                                                    :
                                                        null
                                                }
                                            </div>
                                        </>
                                        )
                                } else {
                                    return null
                                }
                            })
                    }
                </div>
            </div>
            <Search internal={true} />
            <Footer />
        </>

    )
}

export default Produto
